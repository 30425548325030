import {Plus} from "@phosphor-icons/react";
import {CircularProgress, IconButton, Tab, Tabs} from "@mui/material";
import React, {useContext, useEffect} from "react";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import {toast} from "react-toastify";
import toastOptions from "../../../assets/constants/toast";
import dayjs from "dayjs";
import alert from "../../../assets/icons/alert--orange.svg";
import check from "../../../assets/icons/check-circle--green.svg";
import CustomTag from "../../custom/CustomTag";
import CustomSearchInput from "../../custom/CustomSearchInput";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from '@mui/icons-material/Close';
import TaskOption from "./TaskOption";

const TimeEntryList = () => {

    const [tab, setTab] = React.useState(0);
    const [entries, setEntries] = React.useState([]);
    const [projects, setProjects] = React.useState([]);
    const [sections, setSections] = React.useState([]);
    const [tasks, setTasks] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const { token } = useContext(AuthContext);

    useEffect(() => console.log(entries), [entries]);

    useEffect(() => {
        setLoading(true);
        axios.get(process.env.REACT_APP_UPA_API_HOST + 'tracking/data', { headers: { Authorization: `Bearer ${token}` }})
            .then((response) => {
                console.log(response.data);
                setEntries(response.data.entries.map(entry => ({...entry, projectId: entry.task.section.projectId })));
                setProjects(response.data.projects);
                setSections(response.data.sections);
                setTasks(response.data.tasks);
                setLoading(false);
            })
            .catch((error) => {
                toast.error(error.response.data.message, toastOptions);
                console.error("Erreur lors du chargement des tâches", error);
                setLoading(false);
            });
    }, [token]);

    const handleAddEntry = () => {
        setEntries([...entries, { tempId: Date.now(), date: dayjs().format('YYYY-MM-DD'), comments: "", duration: 0 }]);
    };

    const handleEditEntry = (id, updatedEntry) => {
        setEntries(entries.map(entry => ((entry.tempId ? entry.tempId : entry.id) === id ? updatedEntry : entry)));
    };

    const handleActivateEditing = (id) => {
        setEntries(entries.map(entry => entry.id === id ? {...entry, isEditing: true} : {...entry, isEditing: false}));
    }

    const getEntriesDurationSum = entries.reduce((sum, entry) => sum + (entry.tempId || entry.isEditing ? 0 : entry.duration), 0);

    const handleDeleteEntry = (entry) => {
        if(entry.tempId) {
            setEntries(entries.filter(e => e.tempId !== entry.tempId));
        } else {
            axios.delete(process.env.REACT_APP_UPA_API_HOST + `tracking/entries/${entry.id}`, { headers: { Authorization: `Bearer ${token}` }})
                .then(() => setEntries(entries.filter(e => e.id !== entry.id)))
                .catch(error => {
                    toast.error("Une erreur est survenue", toastOptions);
                    console.error("Error deleting entry", error);
                });
        }
    };

    const handleSubmitEntry = entry => {
        if (entry.projectId && entry.taskId && entry.duration > 0) {
            if(entry.tempId) {
                axios.post(process.env.REACT_APP_UPA_API_HOST + 'tracking/entries', entry, { headers: { Authorization: `Bearer ${token}` }})
                    .then(response => {
                        setEntries(entries.map(e => e.tempId === entry.tempId ? {...response.data, projectId: response.data.task.section.projectId } : e));
                    })
                    .catch(error => {
                        toast.error("Une erreur est survenue", toastOptions);
                        console.error("Error saving entry", error);
                    });
            } else {
                axios.put(process.env.REACT_APP_UPA_API_HOST + `tracking/entries/${entry.id}`, entry, { headers: { Authorization: `Bearer ${token}` }})
                    .then(response => {
                        setEntries(entries.map(e => e.id === entry.id ? {...response.data, projectId: response.data.task.section.projectId } : e));
                    })
                    .catch(error => {
                        toast.error("Une erreur est survenue", toastOptions);
                        console.error("Error saving entry", error);
                    });
            }
        } else {
            toast.error("Merci de remplir tous les champs", toastOptions);
        }
    }

    return !loading ? (
        <div className="view-container">
            <div className="view-header">
                <div className="header-title projects">
                    <h1>Saisie d'activité</h1>
                </div>
                <button className="main-button blue-button" onClick={handleAddEntry}>
                    <Plus size={16} weight="bold"/>
                    Nouvelle saisie
                </button>
            </div>
            <div className="tabs-container">
                <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)}>
                    <Tab value={0} label={"Auj"}/>
                    <Tab value={1} label={"Hier"}/>
                    <Tab value={2} label={"Demain"}/>
                    <Tab value={3} label={"Tout"}/>
                </Tabs>
                <div role="tabpanel" id={0} hidden={tab !== 0}>
                    <div className='tracking-hours-remaining-container'>
                        <span>Heures restantes à remplir : </span>
                        <span className='tracking-hours-remaining-value'>{Math.max(0, 7 - getEntriesDurationSum)} h</span>
                        { entries.reduce((sum, entry) => sum + (entry.tempId ? 0 : entry.duration), 0) > 7 ? <img alt='check' src={check} width={18}/> : <img alt='alert' src={alert} width={18}/>}
                    </div>
                    <div className="custom-tracking-table-container">
                        <table className="custom-tracking-table">
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Projet</th>
                                <th>Tâche</th>
                                <th>Commentaires</th>
                                <th>Durée (h)</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {entries.map((entry) => (
                                <tr key={entry.id || entry.tempId}>
                                    <td className='entry-line-date'><CustomTag value={dayjs(entry.date).format('DD/MM/YYYY')}/></td>
                                    {entry.tempId || entry.isEditing ? (
                                        <>
                                            <td>
                                                <CustomSearchInput
                                                    isClearable
                                                    entity={projects}
                                                    labelType="project"
                                                    value={projects.find(p => p.id === entry.projectId)}
                                                    onChange={(value) => handleEditEntry(entry.tempId ? entry.tempId : entry.id, {
                                                        ...entry,
                                                        projectId: value ? value.id : null,
                                                        taskId: null
                                                    })}
                                                    placeholder="Rechercher"
                                                />
                                            </td>
                                            <td>
                                                <CustomSearchInput
                                                    entity={entry.projectId ? tasks.filter(t => sections.some(s => s.projectId === entry.projectId && s.id === t.sectionId)) : []}
                                                    value={entry.taskId ? tasks.find(t => t.id === entry.taskId) : null}
                                                    onChange={(value) => handleEditEntry(entry.tempId ? entry.tempId : entry.id, {
                                                        ...entry,
                                                        taskId: value ? value.id : null
                                                    })}
                                                    placeholder="Rechercher"
                                                    isClearable
                                                    Option={(props) =>
                                                        <TaskOption {...props} entry={entry} tasks={tasks} sections={sections}/>}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    className="classic-input comment-input"
                                                    value={entry.comments}
                                                    onChange={(event) => handleEditEntry(entry.tempId ? entry.tempId : entry.id, {
                                                        ...entry,
                                                        comments: event.target.value
                                                    })}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    step={.25}
                                                    className="classic-input number-input"
                                                    value={entry.duration}
                                                    onChange={(event) => handleEditEntry(entry.tempId ? entry.tempId : entry.id, {
                                                        ...entry,
                                                        duration: parseFloat(event.target.value)
                                                    })}
                                                />
                                            </td>
                                            <td>
                                                <IconButton aria-label="done" size="small" onClick={() => handleSubmitEntry(entry)}>
                                                    <DoneIcon fontSize="inherit"/>
                                                </IconButton>
                                                <IconButton aria-label="done" size="small" onClick={() => {
                                                    if(entry.tempId) {
                                                        handleDeleteEntry(entry);
                                                    } else {
                                                        handleEditEntry(entry.id, {...entry, isEditing: false});
                                                    }
                                                }}>
                                                    <CloseIcon fontSize="inherit"/>
                                                </IconButton>
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            <td onClick={() => handleActivateEditing(entry.id)}>
                                                <span>{projects.find(p => p.id === entry.task.section.projectId).name}</span>
                                            </td>
                                            <td onClick={() => handleActivateEditing(entry.id)}>
                                                <CustomTag value={entry.task.name}/>
                                            </td>
                                            <td onClick={() => handleActivateEditing(entry.id)}>
                                                <span className='comments'>{entry.comments}</span>
                                            </td>
                                            <td onClick={() => handleActivateEditing(entry.id)}>
                                                <CustomTag value={entry.duration + ' h'}/>
                                            </td>
                                            <td>
                                                <IconButton aria-label="delete" size="small" onClick={() => handleEditEntry(entry.id, {...entry, isEditing: true})}>
                                                    <EditIcon fontSize="inherit"/>
                                                </IconButton>
                                                <IconButton aria-label="delete" size="small" onClick={() => handleDeleteEntry(entry)}>
                                                    <DeleteIcon fontSize="inherit"/>
                                                </IconButton>
                                            </td>
                                        </>
                                    )}
                                </tr>
                            ))}
                            <tr>
                                <td colSpan={4}>
                                    <div className='new-entry'>
                                        <button className='main-button add' onClick={handleAddEntry}>Nouvelle saisie</button>
                                    </div>
                                </td>
                            </tr>
                            <tr className='entries-totals'>
                                <td colSpan={4}>{entries.length} tâches</td>
                                <td colSpan={2}>{entries.reduce((sum, entry) => sum + (entry.tempId ? 0 : entry.duration), 0)} h</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div role="tabpanel" id={1} hidden={tab !== 1}>
                    <span>Hier</span>
                </div>
                <div role="tabpanel" id={2} hidden={tab !== 2}>
                    <span>Demain</span>
                </div>
                <div role="tabpanel" id={3} hidden={tab !== 3}>
                    <span>À venir</span>
                </div>
            </div>
        </div>
    ) : (
        <div className="loader-container">
            <CircularProgress size={30}/>
        </div>
    )
}

export default TimeEntryList;