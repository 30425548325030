import React from "react";
import axios from "axios";

const TaskDelete = ({ taskId }) => {
    const handleDelete = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_UPA_API_HOST}tasks/${taskId}`);
            alert("Tâche supprimée avec succès");
        } catch (error) {
            console.error("Erreur lors de la suppression de la tâche", error);
        }
    };

    return <button onClick={handleDelete}>Supprimer la tâche</button>;
};

export default TaskDelete;
