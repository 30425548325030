import React from "react";

const CustomStatus = ({ status }) => {
    const getStatusStyles = (status) => {
        switch (status) {
            case "Avant-vente":
                return {
                    backgroundColor: "var(--blue-50)",
                    dotColor: "var(--blue-400)",
                };
            case "En cours":
                return {
                    backgroundColor: "var(--yellow-50)",
                    dotColor: "var(--yellow-400)",
                };
            case "Terminé":
                return {
                    backgroundColor: "var(--green-50)",
                    dotColor: "var(--green-400)",
                };
            default:
                return {
                    backgroundColor: "var(--black-5)",
                    dotColor: "var(--black-100)",
                };
        }
    };

    const { backgroundColor, dotColor } = getStatusStyles(status);

    return (
        <div
            style={{
                display: "flex",
                padding: "7px 8px",
                height: "30px",
                minWidth: "90px",
                width: "fit-content",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                borderRadius: "15px",
                backgroundColor: backgroundColor,
            }}
        >
            <div
                style={{
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                    backgroundColor: dotColor,
                }}
            ></div>
            <span
                style={{
                    fontWeight: "600",
                }}
            >
                {status}
            </span>
        </div>
    );
};

export default CustomStatus;
