import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import { CircularProgress } from "@mui/material";
import CustomList from "../../custom/CustomList";
import dayjs from "dayjs";
import CustomTag from "../../custom/CustomTag";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import toastOptions from "../../../assets/constants/toast";
import Tippy from "@tippyjs/react";
import favicon from "../../../assets/images/project-favicon.png";
const ClosedProjects = () => {
    const [projects, setProjects] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const { token } = useContext(AuthContext);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [refreshData, setResfreshData] = useState(0);
    const show = () => setPopoverVisible(true);
    const hide = () => setPopoverVisible(false);

    useEffect(() => {
        setIsLoaded(false);
        axios
            .get(process.env.REACT_APP_UPA_API_HOST + "projects/closed", {
                headers: { Authorization: "Bearer " + token },
            })
            .then((response) => {
                if (response.data) {
                    console.log(response.data);
                    setProjects(response.data);
                    setIsLoaded(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [refreshData, token]);

    const formatAmount = (amount, round = 0) => {
        return Number(amount).toLocaleString("fr-FR", {
            maximumFractionDigits: round,
            minimumFractionDigits: round,
        });
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Projet",
                icon: "calendar",
                downloadAccessor: "data.name",
                Cell: (row) => (
                    <Link to={"/admin/projects/" + row.row.original.id}>
                        {row.row.original.data.iconEmoji ? (
                            <span className="margin-right">{row.row.original.data.iconEmoji}</span>
                        ) : row.row.original.data.iconFile ? (
                            <img src={row.row.original.data.iconFile} alt="project-icon" className="margin-right" width={20} />
                        ) : (
                            <img src={favicon} alt="project-icon" className="margin-right" width={20} />
                        )}
                        <span>{row.row.original.data.name}</span>
                    </Link>
                ),
            },
            {
                Header: "Coût",
                accessor: "data.billedCost",
                icon: "euro",
                Cell: ({ cell: { value } }) => <span>{formatAmount(value) + " €"}</span>,
            },
            {
                Header: "Budg.",
                accessor: "data.budget",
                icon: "euro",
                Cell: ({ cell: { value } }) => <span>{formatAmount(value) + " €"}</span>,
            },
            {
                Header: "Marge",
                accessor: "data.costPercentage",
                icon: "percentage",
                Cell: ({ cell: { value } }) => <CustomTag className={value < 100 ? "credit" : "debit"} value={formatAmount(100 - value) + " %"} />,
            },
            {
                Header: "Marge",
                accessor: "data.margin",
                icon: "euro",
                Cell: ({ cell: { value } }) => <CustomTag className={value > 0 ? "credit" : "debit"} value={formatAmount(value) + " €"} />,
            },
            {
                Header: "MEL",
                accessor: "data.dateMEL",
                icon: "calendar",
                Cell: ({ cell: { value } }) => <span>{value ? dayjs(value).format("DD/MM/YYYY") : "Pas de date"}</span>,
            },
        ],
        []
    );

    const popover = (
        <div className="user-options" onClick={popoverVisible ? hide : show}>
            <div className="user-options-item update" onClick={() => handleDailyPersist("daily-persist")}>
                <span>Mettre à jour</span>
            </div>
            <div className="user-options-item update warning" onClick={() => handleDailyPersist("full-persist")}>
                <span>Mettre à jour toute la base</span>
            </div>
        </div>
    );

    const handleDailyPersist = (persistType) => {
        toast
            .promise(
                axios.get(process.env.REACT_APP_UPA_API_HOST + "projects/" + persistType, { headers: { Authorization: "Bearer " + token } }),
                {
                    pending: "Mise à jour des projets",
                    success: "Projets mis à jour",
                    error: "Une erreur est survenue",
                },
                toastOptions
            )
            .then(() => {
                setResfreshData(refreshData + 1);
            });
    };

    return isLoaded ? (
        <div className="view-container">
            <div className="view-header">
                <div className="header-title projects">
                    <h1>Projets terminés</h1>
                    <CustomTag value={projects.length} className="margin-left" />
                </div>
                <div className="view-buttons">
                    <div className="header-info">
                        <span>Dernière MÀJ : {dayjs(projects[0].createdAt).format("DD/MM à HH:mm")}</span>
                    </div>
                    <Tippy
                        content={popover}
                        allowHTML={true}
                        placement={"bottom-end"}
                        offset={[0, 5]}
                        visible={popoverVisible}
                        onClickOutside={hide}
                        interactive={true}
                        appendTo={"parent"}
                    >
                        <button className="squared-button margin-left" onClick={popoverVisible ? hide : show} />
                    </Tippy>
                </div>
            </div>
            <div className="tabs-container">
                <CustomList entity={projects} columns={columns} isFetchable={false} isClickable={false} isDownloadable />
            </div>
        </div>
    ) : (
        <div className="loader-container">
            <CircularProgress size={30} />
        </div>
    );
};

export default ClosedProjects;
