import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PermissionsGate from "../PermissionsGate";
import ProjectList from "./ProjectList";
import ProjectView from "./ProjectView/ProjectView";
import ClosedProjects from "./ClosedProjects";
import ProjectCreationForm from "./ProjectCreationForm";
import AuthContext from "../../../contexts/AuthContext";
import axios from "axios";

const Projects = () => {
    const { token } = useContext(AuthContext);
    const moduleName = "projects";
    const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_UPA_API_HOST}projects-new/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setProjects(response.data);
                console.log(response.data);
                setIsLoading(false);
            } catch (error) {
                console.error("Erreur lors de la récupération des projets", error);
                setIsLoading(false);
            }
        };
        fetchProjects();
    }, [token]);

    const getProjectById = (projectId) => {
        return projects.find((project) => project.id === projectId);
    };

    return (
        <Routes>
            <Route
                path="/:projectId"
                element={
                    <PermissionsGate module="projects-new" submodule="view">
                        <ProjectView getProjectById={getProjectById} isLoading={isLoading} />
                    </PermissionsGate>
                }
            />

            <Route
                path="/"
                element={
                    <PermissionsGate module={moduleName} submodule="view">
                        <ProjectList projects={projects} isLoading={isLoading} />
                    </PermissionsGate>
                }
            />
            <Route
                path="/closed"
                element={
                    <PermissionsGate module={moduleName} submodule="closed">
                        <ClosedProjects />
                    </PermissionsGate>
                }
            />
            <Route
                path="/internal"
                element={
                    <PermissionsGate module={moduleName} submodule="internal">
                        <ProjectList projectType="internal" />
                    </PermissionsGate>
                }
            />
            <Route
                path="/create"
                element={
                    <PermissionsGate module={moduleName} submodule="create">
                        <ProjectCreationForm />
                    </PermissionsGate>
                }
            />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};

export default Projects;
