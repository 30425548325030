import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import * as PhosphorIcons from "@phosphor-icons/react";
import CustomTag from "../../custom/CustomTag";
import CustomSelect from "../../custom/CustomSelect";

const ProjectTask = ({ taskList, setTaskList, onTasksChange, onMembersChange, onDaysChange, selectedMembers, taskDays, estimatedDays, token }) => {
    const [allTasks, setAllTasks] = useState([]);
    const [membersOptions, setMembersOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [members, setMembers] = useState(selectedMembers);
    const [days, setDays] = useState(taskDays);
    const [loadingTasks, setLoadingTasks] = useState(true);

    useEffect(() => {
        setLoadingTasks(true);
        axios
            .get(`${process.env.REACT_APP_UPA_API_HOST}tasks`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                setAllTasks(
                    response.data.map((task) => ({
                        id: task.id,
                        label: task.name,
                        iconCode: task.iconCode,
                        color: task.color,
                    }))
                );
            })
            .catch((error) => console.error("Erreur lors du chargement des tâches", error))
            .finally(() => setLoadingTasks(false));
    }, [token]);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_UPA_API_HOST}users`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                setMembersOptions(
                    response.data.map((member) => ({
                        value: member.id,
                        label: `${member.firstName} ${member.lastName}`,
                    }))
                );
            })
            .catch((error) => console.error("Erreur lors du chargement des membres", error));
    }, [token]);

    useEffect(() => {
        onTasksChange(taskList);
        onMembersChange(members);
        onDaysChange(days);
    }, [taskList, members, days, onTasksChange, onMembersChange, onDaysChange]);

    const handleMemberChange = (taskId, selectedMembers) => {
        const updatedTasks = taskList.map((task) => (task.id === taskId ? { ...task, members: selectedMembers } : task));
        setTaskList(updatedTasks);
        console.log(updatedTasks);
    };

    const handleDaysChange = (taskId, days) => {
        const updatedTasks = taskList.map((task) => (task.id === taskId ? { ...task, days } : task));
        setTaskList(updatedTasks);
    };

    const addTaskToProject = (task) => {
        if (!taskList.some((t) => t.id === task.id)) {
            setTaskList([
                ...taskList,
                {
                    ...task,
                    members: [],
                    days: 0,
                    iconCode: task.iconCode || "",
                    color: task.color || "#000",
                },
            ]);
        }
    };

    const removeTask = (taskId) => {
        const updatedTasks = taskList.filter((task) => task.id !== taskId);
        setTaskList(updatedTasks);
    };

    const filteredTasks = allTasks.filter(
        (task) => !taskList.some((t) => t.id === task.id) && task.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalDaysAllocated = taskList.reduce((sum, task) => sum + (task.days || 0), 0);

    const renderIcon = (iconCode) => {
        const IconComponent = PhosphorIcons[iconCode];
        return IconComponent ? <IconComponent size={16} weight="bold" /> : null;
    };

    return (
        <div className="view-container">
            <div className="view-container-header view-flexStart">
                <div className="view-container-header-content">
                    <strong>Nombre de jours prévus :</strong>
                    <CustomTag className="date" value={`${estimatedDays} jours`} />
                </div>
                <div className="user-info-hr-vertical"></div>
                <div className="view-container-header-content">
                    <strong>Jours restants à remplir :</strong>
                    <CustomTag className="date" value={`${estimatedDays - totalDaysAllocated} jours`} />
                    {totalDaysAllocated === estimatedDays ? (
                        <PhosphorIcons.CheckCircle size={16} weight="bold" color="green" />
                    ) : (
                        <PhosphorIcons.WarningCircle size={16} weight="bold" color="orange" />
                    )}
                </div>
            </div>
            <div className="view-container-content">
                {loadingTasks ? (
                    <div className="loader-container">
                        <CircularProgress size={30} />
                    </div>
                ) : (
                    <>
                        <div className="view-container-content-list">
                            <CustomSelect
                                placeholder="Rechercher une tâche"
                                value={allTasks.find((task) => task.label === searchTerm) || null}
                                onChange={(selectedOption) => {
                                    setSearchTerm(selectedOption ? selectedOption.label : "");
                                }}
                                options={filteredTasks.map((task) => ({
                                    value: task.id,
                                    label: task.label,
                                }))}
                                isSearchable
                            />
                            <div className="task-list">
                                {filteredTasks.map((task) => (
                                    <div key={task.id} className="task-item">
                                        <div className="task-item-text">
                                            <div className="phosphor-button" style={{ color: task.color }}>
                                                {renderIcon(task.iconCode)}
                                            </div>
                                            <span>{task.label}</span>
                                        </div>
                                        <button onClick={() => addTaskToProject(task)} className="cta-button add-button">
                                            <PhosphorIcons.Plus size={16} weight="bold" />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="project-tasks">
                            <p>Tâches du projet</p>
                            <div className="project-tasks-list">
                                {taskList.map((task) => (
                                    <div key={task.id} className="project-task-item">
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "0.5rem",
                                                minWidth: "20rem",
                                                fontSize: "15px",
                                            }}
                                        >
                                            <div className="phosphor-button" style={{ color: task.color }}>
                                                {renderIcon(task.iconCode)}
                                            </div>
                                            <strong>{task.label}</strong>
                                        </div>

                                        <CustomSelect
                                            isMulti
                                            options={membersOptions}
                                            value={task.members || []}
                                            onChange={(selected) => handleMemberChange(task.id, selected)}
                                            placeholder="Rechercher membre"
                                        />
                                        <div className="user-info-hr-vertical"></div>

                                        <div className="tasks-time">
                                            <label htmlFor="days">Jours prévus :</label>
                                            <input
                                                type="number"
                                                className="classic-input"
                                                value={task.days ?? ""}
                                                min="0"
                                                max={estimatedDays - totalDaysAllocated + (task.days || 0)}
                                                step="0.25"
                                                onChange={(e) => handleDaysChange(task.id, parseFloat(e.target.value))}
                                                placeholder="Jours prévus"
                                            />
                                        </div>

                                        <button onClick={() => removeTask(task.id)} className="cta-button delete-button">
                                            <PhosphorIcons.Minus size={16} weight="bold" />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ProjectTask;
