import React from "react";

const ProjectViewCost = ({ project }) => {
    if (!project) {
        return <div>No project data available</div>;
    }

    return (
        <div className="project-view-cost">
            <h2>Project Cost</h2>
        </div>
    );
};

export default ProjectViewCost;
