import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const TimeForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [timeEntry, setTimeEntry] = useState({
        date: "",
        hours: 0,
        description: "",
    });

    useEffect(() => {
        if (id) {
            axios
                .get(`/api/time-entries/${id}`)
                .then((response) => setTimeEntry(response.data))
                .catch((error) =>
                    console.error("Erreur de chargement de l'entrée:", error)
                );
        }
    }, [id]);

    const handleChange = (e) => {
        setTimeEntry({
            ...timeEntry,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const request = id ? axios.put : axios.post;
        const url = id ? `/api/time-entries/${id}` : "/api/time-entries";

        request(url, timeEntry)
            .then(() => navigate("/time"))
            .catch((error) =>
                console.error("Erreur lors de l'enregistrement:", error)
            );
    };

    return (
        <div>
            <h2>{id ? "Modifier" : "Ajouter"} une entrée de temps</h2>
            <form onSubmit={handleSubmit}>
                <label>
                    Date:
                    <input
                        type="date"
                        name="date"
                        value={timeEntry.date}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    Heures:
                    <input
                        type="number"
                        name="hours"
                        value={timeEntry.hours}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    Description:
                    <textarea
                        name="description"
                        value={timeEntry.description}
                        onChange={handleChange}
                    />
                </label>
                <button type="submit">Enregistrer</button>
            </form>
        </div>
    );
};

export default TimeForm;
