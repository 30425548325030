import React from "react";
import CustomTag from "../../../custom/CustomTag";
import { FilePdf } from "@phosphor-icons/react";
import { Link } from "react-router-dom";

const ProjectViewInfo = ({ project }) => {
    console.log("Détails du projet :", project);

    const truncateFileName = (fileName, maxLength = 20) => {
        const extension = fileName.split(".").pop();
        const baseName = fileName.replace(`.${extension}`, "");
        if (baseName.length > maxLength) {
            return `${baseName.slice(0, maxLength)}...`;
        }
        return baseName;
    };

    return (
        <div className="infos-container-wrapper is-white">
            <div className="infos-content-wrapper">
                <div className="infos-title-wrapper info">
                    <p>Informations du projet</p>
                </div>
                <div className="infos-container mel">
                    <span className="infos-container-title">Date de mise en ligne :</span>
                    <CustomTag value={project.dateonlinepublication || "Non renseigné"} />
                </div>
                <div className="infos-container plugins">
                    <span className="infos-container-title">Plugins / Intégrations :</span>
                    <CustomTag value={project.plugins || "Non renseigné"} />
                </div>
                <div className="infos-container assistance">
                    <span className="infos-container-title">Type projet :</span>
                    {project.types && project.types.length > 0 ? (
                        project.types.map((types, index) => <CustomTag key={index} value={types.name} className="default" />)
                    ) : (
                        <CustomTag value="Non renseigné" />
                    )}
                </div>
                <div className="infos-container assistance">
                    <span className="infos-container-title">Catégorie de service :</span>
                    {project.categoryServices && project.categoryServices.length > 0 ? (
                        project.categoryServices.map((categ, index) => <CustomTag key={index} value={categ.name} className="default" />)
                    ) : (
                        <CustomTag value="Non renseigné" />
                    )}
                </div>
                <div className="infos-container assistance">
                    <span className="infos-container-title">Assistance :</span>
                    {project.assistance && project.assistance.length > 0 ? (
                        project.assistance.map((assist, index) => <CustomTag key={index} value={assist.name} className="default" />)
                    ) : (
                        <CustomTag value="Non renseigné" />
                    )}
                </div>
            </div>
            <div className="infos-content-wrapper">
                <div className="infos-title-wrapper contract">
                    <p>Contrats</p>
                </div>
                <div className="infos-container">
                    <div className="infos-container-contract-wrapper">
                        {project.contract && project.contract.length > 0 ? (
                            project.contract.map((contractUrl, index) => {
                                const contractName = contractUrl.split("/").pop();
                                const truncatedName = truncateFileName(contractName);
                                return (
                                    <Link key={index} to="#" onClick={() => window.open(contractUrl, "_blank")} className="infos-container-contract">
                                        <FilePdf size={20} />
                                        <span className="contract-name">{truncatedName}</span>
                                    </Link>
                                );
                            })
                        ) : (
                            <CustomTag value="Aucun contrat disponible" />
                        )}
                    </div>
                </div>
                <div className="infos-title-wrapper config">
                    <p>Configurations</p>
                </div>
                <div className="infos-container mel">
                    <span className="infos-container-title">CMS utilisé :</span>
                    {project.cms && project.cms.length > 0 ? (
                        project.cms.map((cms, index) => <CustomTag key={index} value={cms.name} className="default" />)
                    ) : (
                        <CustomTag value="Non renseigné" />
                    )}
                </div>
                <div className="infos-container languages">
                    <span className="infos-container-title">Langue(s) site :</span>
                    {project.languages && project.languages.length > 0 ? (
                        project.languages.map((lang, index) => {
                            const flagEmoji = lang.code.toUpperCase().replace(/./g, (char) => String.fromCodePoint(127397 + char.charCodeAt()));
                            return <CustomTag key={index} className="default" value={`${flagEmoji} ${lang.name} (${lang.code})`} />;
                        })
                    ) : (
                        <CustomTag value="Non renseigné" />
                    )}
                </div>
                <div className="infos-title-wrapper hebergement">
                    <p>Hébergement</p>
                </div>
                <div className="infos-container mel">
                    <span className="infos-container-title">Hébergeur :</span>
                    {project.hebergeurs && project.hebergeurs.length > 0 ? (
                        project.hebergeurs.map((heb, index) => <CustomTag key={index} value={heb.name} className="default" />)
                    ) : (
                        <CustomTag value="Non renseigné" />
                    )}
                </div>
                <div className="infos-container mel">
                    <span className="infos-container-title">Récupération NDD :</span>
                    {project.gestionNdd && project.gestionNdd.length > 0 ? (
                        project.gestionNdd.map((ndd, index) => <CustomTag key={index} value={ndd.name} className="success" />)
                    ) : (
                        <CustomTag value="Non renseigné" />
                    )}
                </div>
            </div>
            <div className="infos-content-wrapper">
                <div className="infos-title-wrapper link">
                    <p>Liens importants</p>
                </div>
                <div className="infos-container lien-prod">
                    <span className="infos-container-title">Lien PROD :</span>
                    <CustomTag value={project.linkprod} isLink />
                </div>
                <div className="infos-container hourglass">
                    <span className="infos-container-title">Lien DEV :</span>
                    <CustomTag value={project.linkdev} isLink />
                </div>
                <div className="infos-container gear-six">
                    <span className="infos-container-title">Lien MOTEUR :</span>
                    <CustomTag value={project.linkMoteur} isLink />
                </div>
                <div className="infos-container cloud-arrow-up">
                    <span className="infos-container-title">Lien DRIVE :</span>
                    <CustomTag value={project.linkdrive} isLink />
                </div>
            </div>
            <div className="infos-content-wrapper">
                <div className="infos-title-wrapper date">
                    <p>Dates et durée</p>
                </div>
                <div className="infos-container messagerie">
                    <span className="infos-container-title">Début de projet :</span>
                    <CustomTag value={project.dateprojectstart || "Non renseigné"} />
                </div>
                <div className="infos-container adresse-mail">
                    <span className="infos-container-title">Livraison prévue :</span>
                    <CustomTag value={project.dateprojectdelivery || "Non renseigné"} />
                </div>
            </div>
        </div>
    );
};

export default ProjectViewInfo;
