
const TaskOption = ({ innerProps, entry, tasks, sections, ...props }) => {

    const task = props.data;
    const isSubtask = task.parentId !== null;
    const parent = isSubtask ? tasks.find((t) => t.id === task.parentId) : null;
    const section = sections.find((s) => s.id === task.sectionId);

    return (
        <div {...innerProps} className="task-option-container">
            <span className="task-section">{section.name}</span>
            <div className="task-info">
                { isSubtask ? (
                    <div className='subtask-option'>
                        <span className="subtask-parent">{parent.name}</span>
                        <div className="searched-subtask">
                            <span>{task.name}</span>
                        </div>
                    </div>
                ) : (
                    <span className="main-task-option">{task.name}</span>
                )}
            </div>
        </div>
    )
}

export default TaskOption;