import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CustomSelect from "../../../custom/CustomSelect";
import * as PhosphorIcons from "@phosphor-icons/react";
import { toast } from "react-toastify";
import toastOptions from "../../../../assets/constants/toast";

const TaskCreate = ({ setTasks, token }) => {
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [iconCode, setIconCode] = useState("");
    const [color, setColor] = useState("");

    const iconOptions = Object.keys(PhosphorIcons).map((icon) => ({
        value: icon,
        label: icon,
    }));

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_UPA_API_HOST}tasks`,
                { name, description, iconCode, color },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast.success("Tâche créée avec succès", toastOptions);
            setTasks((prevTasks) => [...prevTasks, response.data]);
            navigate(-1);
        } catch (error) {
            console.error("Erreur lors de la création de la tâche", error);
            toast.error("Erreur lors de la création de la tâche", toastOptions);
        }
    };

    const renderIcon = (iconCode, color) => {
        const IconComponent = PhosphorIcons[iconCode];
        if (!IconComponent) {
            console.warn("Icon not found:", iconCode);
            return null;
        }
        return <IconComponent size={20} color={color} weight="bold" />;
    };

    return (
        <div className="view-container">
            <div className="view-header">
                <div className="header-title is-task">
                    <div className="icon">{iconCode && renderIcon(iconCode, color)}</div>
                    <h1>{name || "Créer une nouvelle tâche"}</h1>
                </div>
                <div className="view-buttons">
                    <button className="main-button warning-button" onClick={() => navigate(-1)}>
                        Annuler
                    </button>
                    <button
                        type="submit"
                        className="main-button green-button"
                        onClick={handleSubmit}
                        style={{
                            opacity: name ? 1 : 0.5,
                            cursor: name ? "pointer" : "not-allowed",
                        }}
                    >
                        <PhosphorIcons.Plus size={16} weight="bold" />
                        Créer la tâche
                    </button>
                </div>
            </div>
            <form
                onSubmit={handleSubmit}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                }}
            >
                <div className="form-group-column">
                    <label htmlFor="taskName">Nom de la tâche</label>
                    <input
                        id="taskName"
                        type="text"
                        className="classic-input"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Nom de la tâche"
                        required
                    />
                </div>

                <div className="form-group-column">
                    <label htmlFor="taskDescription">Description</label>
                    <textarea
                        id="taskDescription"
                        value={description}
                        className="classic-input"
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description"
                        rows="4"
                        style={{ minHeight: "150px" }}
                    />
                </div>

                <div className="form-group-column">
                    <label htmlFor="iconCode">Code de l'icône</label>
                    <CustomSelect
                        id="iconCode"
                        value={iconOptions.find((option) => option.value === iconCode) || null}
                        onChange={(selectedOption) => setIconCode(selectedOption?.value)}
                        options={iconOptions}
                        placeholder="Sélectionner une icône"
                        isSearchable
                    />
                </div>

                <div className="form-group-column" style={{ position: "relative" }}>
                    <label htmlFor="color">Couleur (hex)</label>
                    <div
                        className="form-group-content"
                        style={{
                            position: "relative",
                            width: "100%",
                        }}
                    >
                        <input
                            id="color"
                            type="text"
                            className="classic-input"
                            value={color}
                            onChange={(e) => setColor(e.target.value)}
                            placeholder="#000000"
                            required
                            style={{ paddingRight: "2.5rem" }}
                        />
                        <div
                            style={{
                                position: "absolute",
                                right: "0.5rem",
                                top: "50%",
                                transform: "translateY(-50%)",
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                                backgroundColor: color,
                                border: "1px solid #ccc",
                            }}
                        ></div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default TaskCreate;
