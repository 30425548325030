import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import CustomSelect from "../../custom/CustomSelect";
import AuthContext from "../../../contexts/AuthContext";
import ProjectTask from "./ProjectTask";
import { useNavigate } from "react-router-dom";
import {
    Calendar,
    CalendarBlank,
    CalendarPlus,
    Check,
    CheckCircle,
    Cloud,
    CurrencyEur,
    DotsThree,
    FileText,
    GearSix,
    Globe,
    Hash,
    Headset,
    Hourglass,
    Image,
    Info,
    Link,
    Plus,
    Sparkle,
    TextAa,
    Translate,
    Trash,
    X,
} from "@phosphor-icons/react";
import { Tabs, Tab } from "@mui/material";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ProjectCreationForm = () => {
    const { token } = useContext(AuthContext);
    const [projectTypes, setProjectTypes] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [cmsOptions, setCmsOptions] = useState([]);
    const [assistanceOptions, setAssistanceOptions] = useState([]);
    const [members, setMembers] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [projects, setProjects] = useState([]);
    const [categories, setCategories] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [languagePlans, setLanguagePlans] = useState([]);
    const [nddOptions, setNddOptions] = useState([]);
    const [logoPreview, setLogoPreview] = useState(null);
    const [contractFiles, setContractFiles] = useState([]);
    const [isFormValid, setIsFormValid] = useState(false);
    const [clients, setClients] = useState([]);
    const [loadingClients, setLoadingClients] = useState(true);
    const [hebergeurOptions, setHebergeurOptions] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [taskDays, setTaskDays] = useState(0);
    const [taskList, setTaskList] = useState([]);

    const handleTasksChange = (updatedTasks) => setTasks(updatedTasks);
    const handleMembersChange = (updatedMembers) => setSelectedMembers(updatedMembers);
    const handleDaysChange = (updatedDays) => setTaskDays(updatedDays);
    const navigate = useNavigate();

    const [tab, setTab] = useState(0);
    const [formValues, setFormValues] = useState({
        clientId: null,
        reference: "",
        projectName: "",
        description: "",
        projectType: [],
        budget: "",
        contractUrls: [],
        startDate: "",
        deliveryDate: "",
        fileLink: "",
        estimatedMargin: "",
        status: [],
        cms: [],
        assistance: [],
        languages: [],
        categories: [],
        languagePlans: [],
        hostingProvider: [],
        ndd: [],
        hebergeurs: [],
        linkProd: "",
        linkDev: "",
        linkMoteur: "",
        members: [],
        estimatedDays: 0,
    });

    const countryCodeToFlagEmoji = (countryCode) => {
        return countryCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(127397 + char.charCodeAt()));
    };

    const handleTaskChange = (updatedTasks) => {
        setTasks(updatedTasks);
    };

    const fetchAndSetData = (url, token, setData, mapFn, sortKey = "label") => {
        axios
            .get(url, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                const formattedData = response.data.map(mapFn).sort((a, b) => {
                    if (a[sortKey] && b[sortKey]) {
                        return a[sortKey].localeCompare(b[sortKey]);
                    }
                    return 0;
                });
                setData(formattedData);
            })
            .catch((error) => {
                console.error(`Erreur lors de la récupération de ${url}`, error);
            });
    };

    const fetchPennylaneClients = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_UPA_API_HOST}pennylane/get-subscriptions`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            let clientOptions = response.data.map((subscription) => ({
                value: subscription.customer.id,
                label: `${subscription.customer.reference} - ${subscription.customer.name}`,
                id: subscription.customer.id,
                reference: subscription.customer.reference,
            }));

            clientOptions = clientOptions.sort((a, b) => {
                const refA = parseInt(a.reference, 10) || 0;
                const refB = parseInt(b.reference, 10) || 0;
                return refB - refA;
            });

            setClients(clientOptions);
            console.log(clientOptions);
        } catch (error) {
            console.error("Erreur lors de la récupération des clients", error);
        } finally {
            setLoadingClients(false);
        }
    };

    useEffect(() => {
        fetchPennylaneClients();
    }, [token]);

    useEffect(() => {
        fetchAndSetData(process.env.REACT_APP_UPA_API_HOST + "projects-new", token, setProjects, (project) => ({
            value: project.id,
            label: project.name,
        }));
    }, [token]);

    useEffect(() => {
        fetchAndSetData(process.env.REACT_APP_UPA_API_HOST + "projects-new/languages", token, setLanguages, (language) => ({
            value: language.id,
            label: `${countryCodeToFlagEmoji(language.code)} ${language.name} (${language.code})`,
            code: language.code,
        }));
    }, [token]);

    useEffect(() => {
        fetchAndSetData(process.env.REACT_APP_UPA_API_HOST + "projects-new/category", token, setCategories, (category) => ({
            value: category.id,
            label: category.name,
        }));
    }, [token]);

    useEffect(() => {
        fetchAndSetData(process.env.REACT_APP_UPA_API_HOST + "projects-new/types", token, setProjectTypes, (type) => ({
            value: type.id,
            label: type.name,
        }));
    }, [token]);

    useEffect(() => {
        fetchAndSetData(process.env.REACT_APP_UPA_API_HOST + "projects-new/cms", token, setCmsOptions, (cms) => ({ value: cms.id, label: cms.name }));
    }, [token]);

    useEffect(() => {
        fetchAndSetData(process.env.REACT_APP_UPA_API_HOST + "projects-new/status", token, setStatusOptions, (status) => ({
            value: status.id,
            label: status.name,
        }));
    }, [token]);

    useEffect(() => {
        fetchAndSetData(process.env.REACT_APP_UPA_API_HOST + "projects-new/assistance", token, setAssistanceOptions, (assistance) => ({
            value: assistance.id,
            label: assistance.name,
        }));
    }, [token]);

    useEffect(() => {
        fetchAndSetData(process.env.REACT_APP_UPA_API_HOST + "projects-new/languages/plans", token, setLanguagePlans, (plan) => ({
            value: plan.id,
            label: plan.name,
        }));
    }, [token]);

    useEffect(() => {
        fetchAndSetData(process.env.REACT_APP_UPA_API_HOST + "projects-new/gestion-ndd", token, setNddOptions, (ndd) => ({
            value: ndd.id,
            label: ndd.name,
        }));
    }, [token]);

    useEffect(() => {
        fetchAndSetData(process.env.REACT_APP_UPA_API_HOST + "projects-new/hebergeurs", token, setHebergeurOptions, (hebergeur) => ({
            value: hebergeur.id,
            label: hebergeur.name,
        }));
    }, [token]);

    useEffect(() => {
        fetchAndSetData(
            process.env.REACT_APP_UPA_API_HOST + "users",
            token,
            (formattedMembers) => {
                console.log("All fetched members:", formattedMembers);
                setMembers(formattedMembers);
            },
            (member) => ({
                id: member.id,
                firstName: member.firstName,
                lastName: member.lastName,
                role: member.role,
                label: `${member.firstName} ${member.lastName}`,
                backgroundColor: member.backgroundColor,
            })
        );
    }, [token]);

    useEffect(() => {
        fetchAndSetData(process.env.REACT_APP_UPA_API_HOST + "tasks", token, setTasks, (task) => ({
            id: task.id,
            label: task.name,
            members: [],
            days: 0,
        }));
    }, [token]);

    useEffect(() => {
        const isValid =
            formValues.projectName.trim() !== "" &&
            formValues.clientId !== null &&
            formValues.reference !== null &&
            formValues.projectType !== null &&
            formValues.budget.trim() !== "" &&
            formValues.startDate !== "" &&
            formValues.deliveryDate !== "" &&
            formValues.status !== null &&
            formValues.categories !== null &&
            formValues.languages !== null &&
            formValues.assistance !== null &&
            formValues.cms !== null &&
            formValues.logoUrl !== null &&
            contractFiles.length > 0 &&
            formValues.linkProd.trim() !== "" &&
            formValues.linkDev.trim() !== "" &&
            formValues.linkMoteur.trim() !== "" &&
            tasks.length > 0;

        console.log("Logo URL:", formValues.logoUrl);
        console.log("Contract URL:", formValues.contractUrl);

        setIsFormValid(isValid);
    }, [formValues, contractFiles, tasks]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let logoUrl = formValues.logoUrl;
        let contractUrls = [];
        let driveFolderLink = "";

        console.log("Début de la soumission du formulaire");
        console.log("FormValues avant traitement :", formValues);

        if (formValues.logoFile) {
            const formData = new FormData();
            formData.append(
                "path",
                `/altelis-tools/clients/${formValues.clientId.reference}/logo/${formValues.clientId.reference}_Logo.${formValues.logoFile.name
                    .split(".")
                    .pop()}`
            );
            formData.append("image", formValues.logoFile);

            try {
                const response = await axios.post("https://image.store.altelis.com/api/v1/upload", formData);
                logoUrl = `https://image.store.altelis.com${response.data.path}`;
                console.log("Logo URL enregistré :", logoUrl);
            } catch (err) {
                console.error("Erreur lors du téléchargement du logo :", err);
                return;
            }
        }

        if (contractFiles.length > 0) {
            for (let contractFile of contractFiles) {
                const formData = new FormData();
                const formattedFileName = toPascalCase(contractFile.name);
                formData.append(
                    "path",
                    `/altelis-tools/clients/${formValues.clientId.reference}/contrat/${
                        formValues.clientId.reference
                    }_${formattedFileName}.${contractFile.name.split(".").pop()}`
                );
                formData.append("image", contractFile);

                try {
                    const response = await axios.post("https://image.store.altelis.com/api/v1/upload", formData);
                    const contractUrl = `https://image.store.altelis.com${response.data.path}`;
                    contractUrls.push(contractUrl);
                    console.log(`Contrat téléchargé avec succès : ${contractUrl}`);
                } catch (err) {
                    console.error("Erreur lors du téléchargement d'un contrat :", err);
                    return;
                }
            }
        }

        const payload = {
            name: formValues.projectName,
            clientId: formValues.clientId ? formValues.clientId.value : null,
            reference: formValues.reference ? formValues.reference : null,
            iconfile: logoUrl,
            contract: contractUrls,
            budget: formValues.budget,
            marge: formValues.marge ? formValues.marge : null,
            coutestime: formValues.coutestime ? formValues.coutestime : null,
            coutreel: formValues.coutreel ? formValues.coutreel : null,
            dateprojectstart: formValues.startDate,
            dateprojectdelivery: formValues.deliveryDate,
            dateonlinepublication: formValues.dateOnlinePublication ? formValues.dateOnlinePublication : null,
            datepreview: formValues.datePreview ? formValues.datePreview : null,
            linkprod: formValues.linkProd ? formValues.linkProd : null,
            linkdev: formValues.linkDev ? formValues.linkDev : null,
            linkdrive: driveFolderLink,
            members: selectedMembers.map((member) => ({
                id: member.id,
                label: `${member.firstName} ${member.lastName}`,
                role: member.role,
                backgroundColor: member.backgroundColor,
            })),
            cms_ids: formValues.cms ? [formValues.cms.value] : [],
            status_ids: formValues.status ? [formValues.status.value] : [],
            assistance_ids: formValues.assistance ? [formValues.assistance.value] : [],
            language_ids: formValues.languages.map((language) => language.value),
            project_type_ids: formValues.projectType ? [formValues.projectType.value] : [],
            category_service_ids: formValues.categories.map((category) => category.value),
            language_plan_ids: formValues.languagePlans ? [formValues.languagePlans.value] : [],
            gestion_ndd_ids: formValues.ndd ? [formValues.ndd.value] : [],
            hebergeur_ids: formValues.hebergeurs ? [formValues.hebergeurs.value] : [],
            joursestimees: formValues.joursEstimees ? formValues.joursEstimees : null,
            jourspassees: formValues.joursPassees ? formValues.joursPassees : null,
            joursrestants: formValues.joursRestants ? formValues.joursRestants : null,
            tasks: tasks.map((task) => ({
                id: task.id,
                days: task.days,
                members: task.members.map((member) => member.value),
            })),
        };

        console.log("Payload final avant création du projet :", payload);

        try {
            const projectResponse = await axios.post(`${process.env.REACT_APP_UPA_API_HOST}projects-new/create-project`, payload, {
                headers: { Authorization: "Bearer " + token },
            });
            console.log("Projet créé avec succès :", projectResponse.data);

            const folderName = `${formValues.clientId.reference} - ${formValues.projectName}`;
            try {
                const driveResponse = await axios.post("http://localhost:8080/api/google/create-drive-folder", { folderName });
                driveFolderLink = driveResponse.data.folderLink;
                console.log("Dossier créé avec succès dans Google Drive, lien :", driveFolderLink);

                await axios.put(
                    `${process.env.REACT_APP_UPA_API_HOST}projects-new/${projectResponse.data.id}`,
                    { linkdrive: driveFolderLink },
                    {
                        headers: { Authorization: "Bearer " + token },
                    }
                );
                console.log("Lien du dossier Google Drive ajouté au projet avec succès.");
            } catch (error) {
                console.error("Erreur lors de la création du dossier dans Google Drive :", error);
                alert("Erreur lors de la création du dossier dans Google Drive.");
            }

            alert("Projet créé avec succès");
            navigate("/admin/projects");
        } catch (error) {
            console.error("Erreur lors de la création du projet", error);
            alert("Erreur lors de la création du projet.");
        }
    };

    useEffect(() => {
        if (formValues.budget) {
            setFormValues((prevValues) => ({
                ...prevValues,
                estimatedDays: parseFloat(formValues.budget) / 600,
            }));
        }
    }, [formValues.budget]);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            handleLogoUpload({ target: { files } });
        }
    };

    const formatClientId = (label) => {
        return label
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join("_");
    };

    const toPascalCase = (str) => {
        return str
            .replace(/\.[^/.]+$/, "")
            .replace(/[^a-zA-Z0-9]/g, " ")
            .replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .replace(/\s+/g, "_");
    };

    const handleLogoUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setLogoPreview(URL.createObjectURL(file));
            setFormValues((prevValues) => ({
                ...prevValues,
                logoFile: file,
            }));
        }
    };

    const handleContractUpload = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const newFiles = [...contractFiles, ...Array.from(files)];
            setContractFiles(newFiles);
            console.log("Nouveaux fichiers de contrat ajoutés :", newFiles);
        }
    };

    const handleContractRemove = (index) => {
        const updatedFiles = contractFiles.filter((_, i) => i !== index);
        setContractFiles(updatedFiles);
    };

    return (
        <div className="view-container">
            <div className="view-header">
                <div className="header-title projects">
                    <h1>Création d’un nouveau projet</h1>
                </div>

                <div className="view-buttons">
                    <button className="main-button warning-button" onClick={() => navigate(-1)}>
                        Annuler
                    </button>
                    <button
                        className="main-button green-button"
                        type="submit"
                        form="project-form"
                        disabled={!isFormValid}
                        onClick={handleSubmit}
                        style={{
                            opacity: isFormValid ? 1 : 0.5,
                            cursor: isFormValid ? "pointer" : "not-allowed",
                        }}
                    >
                        <Check size={16} weight="bold" />
                        Enregistrer et créer
                    </button>
                </div>
            </div>

            <div className="list-container border">
                <Tabs value={tab} onChange={handleTabChange}>
                    <Tab label="Informations du projet" />
                    <Tab label="Tâches du projet" />
                </Tabs>

                {tab === 0 && (
                    <form id="project-form" onSubmit={handleSubmit}>
                        <section className="project-form-section">
                            <div className="project-form-header">
                                <div className="project-form-header-title">
                                    <h2>Informations du projet</h2>
                                    <Info size={16} weight="bold" />
                                </div>
                                <div className="full-width row-input">
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "4px",
                                        }}
                                    >
                                        <label>Type de projet :</label>
                                    </div>
                                    <CustomSelect
                                        options={projectTypes}
                                        name="projectType"
                                        value={formValues.projectType}
                                        onChange={(selected) => {
                                            console.log("Type de projet sélectionné :", selected);
                                            setFormValues({
                                                ...formValues,
                                                projectType: selected,
                                            });
                                        }}
                                        placeholder="Sélectionnez un type de projet"
                                    />
                                </div>
                            </div>
                            <article className="project-form-article">
                                <div className="full-width">
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "4px",
                                        }}
                                    >
                                        <TextAa size={14} weight="bold" />
                                        <label>Nom du projet :</label>
                                    </div>
                                    <input
                                        type="text"
                                        name="projectName"
                                        value={formValues.projectName}
                                        onChange={handleInputChange}
                                        required
                                        className="classic-input"
                                    />
                                </div>

                                <div className="form-group-full">
                                    <div className="full-width">
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "5px",
                                            }}
                                        >
                                            <Hash size={14} weight="bold" />
                                            <label>Client associé :</label>
                                        </div>
                                        <CustomSelect
                                            options={clients}
                                            name="clientId"
                                            value={formValues.clientId}
                                            onChange={(selected) => {
                                                console.log("Client sélectionné :", selected);
                                                setFormValues({
                                                    ...formValues,
                                                    clientId: selected,
                                                });
                                            }}
                                            placeholder="Sélectionnez un client"
                                            formatOptionLabel={({ label }) => (
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: label.replace(/^(.*?) -/, "<strong>$1</strong> -"),
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="full-width">
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "5px",
                                            }}
                                        >
                                            <CurrencyEur size={14} weight="bold" />
                                            <label>Budget :</label>
                                        </div>
                                        <input
                                            type="number"
                                            name="budget"
                                            value={formValues.budget}
                                            onChange={handleInputChange}
                                            required
                                            className="classic-input"
                                            min="0"
                                            step="1"
                                            pattern="\d*"
                                        />
                                    </div>
                                </div>
                                <div className="form-group-full">
                                    <div className="full-width">
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "5px",
                                            }}
                                        >
                                            <Sparkle size={14} weight="bold" />
                                            <label>Catégorie de service :</label>
                                        </div>
                                        <CustomSelect
                                            options={categories}
                                            name="categories"
                                            value={formValues.categories}
                                            onChange={(selected) => {
                                                console.log("Catégorie sélectionnée :", selected);
                                                setFormValues({
                                                    ...formValues,
                                                    categories: Array.isArray(selected) ? selected : selected ? [selected] : [],
                                                });
                                            }}
                                            placeholder="Sélectionnez une catégorie de service"
                                        />
                                    </div>
                                    <div className="full-width">
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "5px",
                                            }}
                                        >
                                            <Headset size={14} weight="bold" />
                                            <label>Assistance :</label>
                                        </div>
                                        <CustomSelect
                                            options={assistanceOptions}
                                            name="assistance"
                                            value={formValues.assistance}
                                            onChange={(selected) => {
                                                console.log("Assistance sélectionnée :", selected);
                                                setFormValues({
                                                    ...formValues,
                                                    assistance: selected,
                                                });
                                            }}
                                            placeholder="Sélectionnez une assistance"
                                        />
                                    </div>
                                </div>
                            </article>
                            <div className="project-form-header-title">
                                <h2>Configurations</h2>
                                <GearSix size={16} weight="bold" />
                            </div>
                            <article className="project-form-article">
                                <div className="full-width">
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "4px",
                                        }}
                                    >
                                        <GearSix size={14} weight="bold" />
                                        <label>CMS :</label>
                                    </div>
                                    <CustomSelect
                                        options={cmsOptions}
                                        name="cms"
                                        value={formValues.cms}
                                        onChange={(selected) => {
                                            console.log("CMS sélectionné :", selected);
                                            setFormValues({
                                                ...formValues,
                                                cms: selected,
                                            });
                                        }}
                                        placeholder="Sélectionnez un CMS"
                                    />
                                </div>
                                <div className="form-group-full">
                                    <div className="full-width">
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "5px",
                                            }}
                                        >
                                            <Translate size={14} weight="bold" />
                                            <label>Outils de traduction :</label>
                                        </div>
                                        <CustomSelect
                                            options={languagePlans}
                                            name="languagePlans"
                                            value={formValues.languagePlans}
                                            onChange={(selected) => {
                                                console.log("Outils de traduction sélectionnés :", selected);
                                                setFormValues({
                                                    ...formValues,
                                                    languagePlans: selected,
                                                });
                                            }}
                                            placeholder="Sélectionnez un outil de traduction"
                                        />
                                    </div>
                                    <div className="full-width">
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "5px",
                                            }}
                                        >
                                            <Globe size={14} weight="bold" />
                                            <label>Langue(s) du site :</label>
                                        </div>
                                        <CustomSelect
                                            isMulti
                                            options={languages}
                                            name="languages"
                                            value={formValues.languages}
                                            onChange={(selected) => {
                                                console.log("Langues sélectionnées :", selected);
                                                setFormValues({
                                                    ...formValues,
                                                    languages: selected,
                                                });
                                            }}
                                            placeholder="Sélectionnez une langue"
                                        />
                                    </div>
                                </div>
                            </article>
                            <div className="project-form-header-title">
                                <h2>Hébergeur</h2>
                                <Cloud size={16} weight="bold" />
                            </div>
                            <article className="project-form-article">
                                <div className="form-group-full">
                                    <div className="full-width">
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "5px",
                                            }}
                                        >
                                            <GearSix size={14} weight="bold" />
                                            <label>Hébergeur :</label>
                                        </div>
                                        <CustomSelect
                                            options={hebergeurOptions}
                                            name="hebergeurs"
                                            value={formValues.hebergeurs}
                                            onChange={(selected) => {
                                                console.log("Hébergeurs sélectionnés :", selected);
                                                setFormValues({
                                                    ...formValues,
                                                    hebergeurs: selected,
                                                });
                                            }}
                                            placeholder="Sélectionnez un hébergeur"
                                        />
                                    </div>

                                    <div className="full-width">
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "5px",
                                            }}
                                        >
                                            <DotsThree size={14} weight="bold" />
                                            <label>Récupération NDD :</label>
                                        </div>
                                        <CustomSelect
                                            options={nddOptions}
                                            name="ndd"
                                            value={formValues.ndd}
                                            onChange={(selected) => {
                                                setFormValues({
                                                    ...formValues,
                                                    ndd: selected,
                                                });
                                            }}
                                            placeholder="Sélectionnez un NDD"
                                        />
                                    </div>
                                </div>
                            </article>
                            <div className="project-form-header-title">
                                <h2>Dates du projet</h2>
                                <CalendarBlank size={16} weight="bold" />
                            </div>
                            <article className="project-form-article">
                                <div className="form-group-full">
                                    <div className="full-width">
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "5px",
                                            }}
                                        >
                                            <CalendarPlus size={14} weight="bold" />
                                            <label>Date de début du projet :</label>
                                        </div>
                                        <DatePicker
                                            selected={formValues.startDate}
                                            onChange={(date) =>
                                                setFormValues((prevValues) => ({
                                                    ...prevValues,
                                                    startDate: date,
                                                }))
                                            }
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Sélectionner la date"
                                            className="classic-input"
                                        />
                                    </div>

                                    <div className="full-width">
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "5px",
                                            }}
                                        >
                                            <Calendar size={14} weight="bold" />
                                            <label>Date de livraison prévue :</label>
                                        </div>
                                        <DatePicker
                                            selected={formValues.deliveryDate}
                                            onChange={(date) =>
                                                setFormValues((prevValues) => ({
                                                    ...prevValues,
                                                    deliveryDate: date,
                                                }))
                                            }
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="Sélectionner la date"
                                            className="classic-input"
                                        />
                                    </div>
                                </div>
                            </article>
                            <div className="project-form-header-title">
                                <h2>Liens importants</h2>
                                <Link size={16} weight="bold" />
                            </div>
                            <article className="project-form-article">
                                <div className="full-width">
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "4px",
                                        }}
                                    >
                                        <CheckCircle size={14} weight="bold" />
                                        <label>Lien PROD :</label>
                                    </div>
                                    <input
                                        type="url"
                                        name="linkProd"
                                        value={formValues.linkProd}
                                        onChange={handleInputChange}
                                        className="classic-input"
                                    />
                                </div>

                                <div className="full-width">
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "4px",
                                        }}
                                    >
                                        <Hourglass size={14} weight="bold" />
                                        <label>Lien DEV :</label>
                                    </div>
                                    <input
                                        type="url"
                                        name="linkDev"
                                        value={formValues.linkDev}
                                        onChange={handleInputChange}
                                        className="classic-input"
                                    />
                                </div>

                                <div className="full-width">
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "4px",
                                        }}
                                    >
                                        <GearSix size={14} weight="bold" />
                                        <label>Lien MOTEUR :</label>
                                    </div>
                                    <input
                                        type="url"
                                        name="linkMoteur"
                                        value={formValues.linkMoteur}
                                        onChange={handleInputChange}
                                        className="classic-input"
                                    />
                                </div>
                            </article>
                        </section>

                        <aside className="project-form-aside">
                            <div className="full-width aside">
                                <label>Statut du projet</label>
                                <CustomSelect
                                    options={statusOptions}
                                    name="status"
                                    value={formValues.status}
                                    onChange={(selected) => {
                                        console.log("Statut sélectionné :", selected);
                                        setFormValues({
                                            ...formValues,
                                            status: selected,
                                        });
                                    }}
                                    placeholder="Sélectionnez un statut"
                                />
                            </div>

                            <div className={`full-width aside ${logoPreview ? "logo-upload" : ""}`}>
                                <label>Logo</label>
                                {logoPreview ? (
                                    <div className="logo-preview-wrapper">
                                        <div className="logo-preview">
                                            <img src={logoPreview} alt="Aperçu du logo" style={{ maxWidth: "100px" }} />
                                            <div className="logo-remove-overlay" onClick={() => setLogoPreview(null)}>
                                                <Trash size={20} weight="bold" />
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className="logo-dropzone"
                                        onClick={() => document.getElementById("logo-input").click()}
                                        onDrop={handleDrop}
                                        onDragOver={(e) => e.preventDefault()}
                                    >
                                        <Image size={20} weight="bold" />
                                        <p>Faites glisser et déposez l'image ou cliquez ici</p>
                                        <input type="file" id="logo-input" accept="image/*" onChange={handleLogoUpload} style={{ display: "none" }} />
                                    </div>
                                )}
                            </div>

                            <div className="full-width aside">
                                <div className="full-width contract-upload">
                                    <label>Contrats</label>
                                    <button
                                        className="main-button blue-button"
                                        type="button"
                                        onClick={() => document.getElementById("contract-input").click()}
                                    >
                                        <Plus size={16} weight="bold" />
                                        Ajouter un fichier
                                    </button>
                                </div>
                                {contractFiles.length > 0 && (
                                    <div className="contract-details-list">
                                        {contractFiles.map((file, index) => (
                                            <div key={index} className="contract-details">
                                                <FileText size={16} weight="bold" />
                                                <p>{file.name}</p>
                                                <button type="button" className="main-button" onClick={() => handleContractRemove(index)}>
                                                    <X size={16} weight="bold" />
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                <input
                                    type="file"
                                    id="contract-input"
                                    accept="application/pdf"
                                    multiple
                                    onChange={handleContractUpload}
                                    style={{ display: "none" }}
                                />
                            </div>

                            <div className="full-width aside">
                                <label>Membres du projet</label>
                                <CustomSelect
                                    isMulti
                                    options={members}
                                    value={selectedMembers}
                                    onChange={(selected) => {
                                        console.log("Membres sélectionnés :", selected);
                                        setSelectedMembers(selected);
                                    }}
                                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                                    getOptionValue={(option) => option.id}
                                    placeholder="Rechercher un membre"
                                />
                            </div>
                        </aside>
                    </form>
                )}

                {tab === 1 && (
                    <ProjectTask
                        taskList={taskList}
                        setTaskList={setTaskList}
                        onTasksChange={handleTaskChange}
                        onMembersChange={handleMembersChange}
                        onDaysChange={handleDaysChange}
                        selectedMembers={selectedMembers}
                        estimatedDays={formValues.estimatedDays}
                        taskDays={taskDays}
                        token={token}
                    />
                )}
            </div>
        </div>
    );
};

export default ProjectCreationForm;
