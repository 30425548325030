import React, { useContext, useMemo, useState, useEffect } from "react";
import AuthContext from "../../../contexts/AuthContext";
import CustomList from "../../custom/CustomList";
import CustomTag from "../../custom/CustomTag";
import CustomStatus from "../../custom/CustomStatus";
import dayjs from "dayjs";
import { Button, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, TextField, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Plus, Trash } from "@phosphor-icons/react";
import { toast } from "react-toastify";
import toastOptions from "../../../assets/constants/toast";
import axios from "axios";

const ProjectList = ({ projects, isLoading }) => {
    const { token } = useContext(AuthContext);
    const [anchorElMembers, setAnchorElMembers] = useState(null);
    const [anchorElStatus, setAnchorElStatus] = useState(null);
    const [openFiltersModal, setOpenFiltersModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_UPA_API_HOST + "users", {
                    headers: { Authorization: "Bearer " + token },
                });
                setUsers(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des utilisateurs", error);
            }
        };

        fetchUsers();
    }, [token]);

    const handleDelete = async (projectId) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer ce projet ?")) {
            try {
                await axios.delete(`${process.env.REACT_APP_UPA_API_HOST}projects-new/${projectId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                toast.success("Projet supprimé avec succès", toastOptions);
            } catch (error) {
                console.error("Erreur lors de la suppression du projet", error);
                toast.error("Une erreur est survenue lors de la suppression", toastOptions);
            }
        }
    };

    const allowedRoles = ["Chef de Projet", "CEO", "Administrateur", "Développeur Front-End", "Développeur Back-End"];
    const filteredUsers = useMemo(() => {
        return users.filter(
            (user) =>
                allowedRoles.includes(user.role.name) &&
                (user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) || user.lastName.toLowerCase().includes(searchQuery.toLowerCase()))
        );
    }, [users, searchQuery]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleOpenMembersMenu = (event) => {
        setAnchorElMembers(event.currentTarget);
    };

    const handleCloseMembersMenu = () => {
        setAnchorElMembers(null);
    };

    const handleOpenStatusMenu = (event) => {
        setAnchorElStatus(event.currentTarget);
    };

    const handleCloseStatusMenu = () => {
        setAnchorElStatus(null);
    };

    const handleOpenFiltersModal = () => {
        setOpenFiltersModal(true);
    };

    const handleCloseFiltersModal = () => {
        setOpenFiltersModal(false);
    };

    const colProjects = useMemo(
        () => [
            {
                Header: "Projet",
                accessor: "name",
                icon: "project",
                Cell: ({ row }) => (
                    <div className="project-detail">
                        <img src={row.original.iconfile} alt={row.original.name} className="project-icon" />
                        <span>{row.original.name}</span>
                    </div>
                ),
            },
            {
                Header: "Statut",
                accessor: "status",
                icon: "role",
                Cell: ({ value }) => {
                    return value.map((status, index) => <CustomStatus key={index} status={status.name} />);
                },
            },
            {
                Header: "Jours restants",
                accessor: "remaining_days",
                icon: "calendar",
                Cell: ({ row }) => {
                    const endDate = dayjs(row.original.dateprojectdelivery);
                    const remainingDays = endDate.diff(dayjs(), "day");
                    return remainingDays >= 0 ? `${remainingDays} jours` : "Terminé";
                },
            },
            {
                Header: "Budget",
                accessor: "budget",
                icon: "euro",
                Cell: ({ value }) => {
                    const budgetValue = parseFloat(value).toLocaleString("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                    });
                    return <CustomTag value={budgetValue} className="default" />;
                },
            },
            {
                Header: "Marge",
                accessor: "marge",
                icon: "marge",
            },
            {
                Header: "Durée du projet",
                accessor: "duration",
                icon: "conso",
                Cell: ({ row }) => {
                    const startDate = dayjs(row.original.dateprojectstart);
                    const endDate = dayjs(row.original.dateprojectdelivery);
                    const duration = endDate.diff(startDate, "day");
                    return `${duration} jours`;
                },
            },
            {
                Header: "Actions",
                accessor: "actions",
                icon: "pen",
                Cell: ({ row }) => (
                    <button onClick={() => handleDelete(row.original.id)} className="cta-button delete-button">
                        <Trash size={16} weight="bold" />
                    </button>
                ),
            },
        ],
        []
    );

    return (
        <div className="view-container">
            <div className="view-header">
                <div className="header-title projects">
                    <h1>Projets</h1>
                </div>
                <button className="main-button blue-button" onClick={() => navigate("create")}>
                    <Plus size={16} weight="bold" />
                    Créer un nouveau projet
                </button>
            </div>
            <div className="tabs-container">
                <div className="filters-wrapper">
                    <div className="filters-buttons">
                        <Button className="dropdown-button" onClick={handleOpenMembersMenu}>
                            Membres
                        </Button>
                        <Menu anchorEl={anchorElMembers} open={Boolean(anchorElMembers)} onClose={handleCloseMembersMenu} className="members-menu">
                            <TextField
                                variant="outlined"
                                placeholder="Rechercher un membre"
                                size="small"
                                fullWidth
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            {filteredUsers.length > 0 ? (
                                filteredUsers.map((user) => (
                                    <MenuItem key={user.id} onClick={handleCloseMembersMenu} className="member">
                                        <div
                                            className="member-icon"
                                            style={{
                                                backgroundColor: user.backgroundColor,
                                            }}
                                        >
                                            {user.firstName.charAt(0)}
                                            {user.lastName.charAt(0)}
                                        </div>
                                        {user.firstName} {user.lastName}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem disabled>Aucun membre trouvé</MenuItem>
                            )}
                        </Menu>

                        <Button className="dropdown-button" onClick={handleOpenStatusMenu}>
                            Statut
                        </Button>
                        <Menu anchorEl={anchorElStatus} open={Boolean(anchorElStatus)} onClose={handleCloseStatusMenu}>
                            <MenuItem onClick={handleCloseStatusMenu}>Ongoing</MenuItem>
                            <MenuItem onClick={handleCloseStatusMenu}>Terminé</MenuItem>
                        </Menu>

                        <Button className="filter-button" onClick={handleOpenFiltersModal}>
                            Ajouter un filtre
                        </Button>
                    </div>
                </div>
                {isLoading ? (
                    <div className="loader-container">
                        <CircularProgress size={30} />
                    </div>
                ) : (
                    <CustomList entity={projects} columns={colProjects} token={token} isClickable={true} isDownloadable={true} isScrollable={true} />
                )}
            </div>

            <Dialog open={openFiltersModal} onClose={handleCloseFiltersModal}>
                <DialogTitle>Ajouter un filtre</DialogTitle>
                <DialogContent>
                    <TextField autoFocus margin="dense" label="Filtre" type="text" fullWidth variant="outlined" />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseFiltersModal} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleCloseFiltersModal} color="primary">
                        Ajouter
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ProjectList;
