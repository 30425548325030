import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { CircularProgress, Tabs, Tab } from "@mui/material";
import { Eye } from "@phosphor-icons/react";

import CustomTag from "../../../custom/CustomTag";
import CustomStatus from "../../../custom/CustomStatus";

import ProjectViewInfo from "./ProjectViewInfo";
import ProjectViewCost from "./ProjectViewCost";

const ProjectView = ({ getProjectById, isLoading }) => {
    const { projectId } = useParams();
    const project = getProjectById(projectId);

    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(() => {
        if (project) {
            document.title =
                `${project.name} | Altelis Tools` ||
                "Nom du projet indisponible - Altelis Tools";
        }
    }, [project]);

    if (isLoading) {
        return (
            <div className="loader-container">
                <CircularProgress size={30} />
            </div>
        );
    }

    if (!project) {
        return <p>Projet introuvable.</p>;
    }

    return (
        <div className="view-container">
            <div className="view-header">
                <div className="header-title none">
                    <img
                        src={project.iconfile}
                        alt={project.name}
                        className="project-icon"
                    />
                    <h1>{project.name}</h1>
                </div>
                <div className="view-actions">
                    <button className="main-button">
                        <Eye size={16} weight="bold" />
                        Voir la fiche client
                    </button>
                </div>
            </div>

            <div className="view-container-header">
                <div className="view-container__content">
                    <strong>Statut :</strong> {project.clientId}
                    {project.status.map((status, index) => (
                        <CustomStatus key={index} status={status.name} />
                    ))}
                </div>
                <div className="view-container__content">
                    <strong>Budget :</strong>
                    <CustomTag
                        value={parseFloat(project.budget).toLocaleString(
                            "fr-FR",
                            {
                                style: "currency",
                                currency: "EUR",
                            }
                        )}
                    />
                </div>
                <div className="view-container__content">
                    <strong>Date de début :</strong>{" "}
                    {dayjs(project.dateprojectstart).format("DD/MM/YYYY")}
                </div>
                <div className="view-container__content">
                    <strong>Date de livraison :</strong>{" "}
                    {dayjs(project.dateprojectdelivery).format("DD/MM/YYYY")}
                </div>
                <div className="view-container__content">
                    <strong>Membres du projet :</strong>
                    <div className="members-container">
                        {project.members.map((member, index) => {
                            const initials = member.label
                                .split(" ")
                                .map((word) => word.charAt(0))
                                .join("")
                                .toUpperCase();

                            return (
                                <div
                                    className="member-icon"
                                    style={{
                                        backgroundColor: member.backgroundColor,
                                    }}
                                    key={index}
                                >
                                    {initials}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className="tabs-container">
                <Tabs value={tab} onChange={handleTabChange}>
                    <Tab value={0} label="Informations du projet" />
                    <Tab value={1} label="Tâches du projet" />
                    <Tab value={2} label="Coût par membre" />
                    <Tab value={3} label="Activité du projet" />
                </Tabs>

                <div
                    role="tabpanel"
                    hidden={tab !== 0}
                    style={{
                        height: "100%",
                    }}
                >
                    <ProjectViewInfo project={project} />
                </div>

                <div role="tabpanel" hidden={tab !== 1}>
                    <p>
                        <strong>Statut :</strong>
                        {project.status.map((status, index) => (
                            <CustomStatus key={index} status={status.name} />
                        ))}
                    </p>
                </div>

                <div role="tabpanel" hidden={tab !== 2}>
                    <ProjectViewCost project={project} />
                </div>

                <div role="tabpanel" hidden={tab !== 3}>
                    <p>
                        <strong>Liens :</strong>
                        <ul>
                            <li>
                                <a
                                    href={project.linkprod}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Lien production
                                </a>
                            </li>
                            <li>
                                <a
                                    href={project.linkdev}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Lien développement
                                </a>
                            </li>
                            {project.linkdrive && (
                                <li>
                                    <a
                                        href={project.linkdrive}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Lien Google Drive
                                    </a>
                                </li>
                            )}
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ProjectView;
