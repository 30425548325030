import React, { useState, useEffect, useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import TaskList from "./TaskList";
import TaskCreate from "./TaskCreate";
import TaskUpdate from "./TaskUpdate";
import TaskDelete from "./TaskDelete";
import AuthContext from "../../../../contexts/AuthContext";
import axios from "axios";

const Task = () => {
    const { token } = useContext(AuthContext);
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_UPA_API_HOST}tasks`, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                setTasks(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Erreur lors de la récupération des tâches", error);
                setError("Erreur lors de la récupération des tâches");
                setLoading(false);
            });
    }, [token]);

    if (loading) return <div>Chargement des tâches...</div>;
    if (error) return <div>{error}</div>;

    return (
        <Routes>
            <Route path="/" element={<TaskList tasks={tasks} token={token} />} />
            <Route path="/create" element={<TaskCreate setTasks={setTasks} token={token} />} />
            <Route path="/update/:taskId" element={<TaskUpdate tasks={tasks} setTasks={setTasks} token={token} />} />
            <Route path="/delete/:taskId" element={<TaskDelete tasks={tasks} setTasks={setTasks} token={token} />} />
            <Route path="*" element={<Navigate to="/admin/crud/tasks" />} />
        </Routes>
    );
};

export default Task;
