import React from "react";
import {Routes, Route, Navigate} from "react-router-dom";
import TimeEntryForm from "./TimeEntryForm";
import TimeEntryList from "./TimeEntryList";

const TimeEntries = () => {
    return (
        <Routes>
            <Route path="/time-entries" element={<TimeEntryList />} />
            <Route path="/time-entries/new" element={<TimeEntryForm />} />
            <Route path="/time-entries/:id/edit" element={<TimeEntryForm />} />
            <Route path="*" element={<Navigate to="time-entries" />} />
        </Routes>
    );
};

export default TimeEntries;
