import React, { useContext, useState } from "react";
import { Link, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import mainLogo from "../../assets/images/main-logo.png";
import AuthContext from "../../contexts/AuthContext";
import Accounting from "./accounting/Accounting";
import "../../assets/styles/AdminPanel.css";
import Tippy from "@tippyjs/react";
import CustomSearchInput from "../custom/CustomSearchInput";
import axios from "axios";
import UsersInfos from "./users/Users";
import { CircularProgress } from "@mui/material";
import Hiring from "./hiring/Hiring";
import CustomNavbarOption from "../custom/CustomNavbarOption";
import Projects from "./projects/Projects";
import Inventaire from "./inventaire/Inventaire";
import Roles from "./roles/Roles";
import Chatbot from "./chatbot/Chatbot";
import Crud from "./crud/Crud";
import TimeEntries from "./tracking/TimeEntries";
import {
    ArrowLeft,
    CaretDown,
    SignOut,
    User,
    UserCircleDashed,
    Users,
} from "@phosphor-icons/react";

const AdminPanel = ({ countData }) => {
    const adminLocation = window.location.href.replace(
        process.env.REACT_APP_UPA_INTERFACE_HOST + "admin/",
        ""
    );
    const navigate = useNavigate();
    const { user, token, setToken, setUser } = useContext(AuthContext);
    const [searchData, setSearchData] = useState([]);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const show = () => setPopoverVisible(true);
    const hide = () => setPopoverVisible(false);

    React.useEffect(() => {
        axios
            .get(process.env.REACT_APP_UPA_API_HOST + "books/customers", {
                headers: { Authorization: "Bearer " + token },
            })
            .then((response) => {
                setSearchData(response.data);
            })
            .catch((error) =>
                console.log("Error fetching search data:", error)
            );

        axios
            .get(
                process.env.REACT_APP_UPA_API_HOST +
                    "pennylane/get-subscriptions",
                {
                    headers: { Authorization: "Bearer " + token },
                }
            )
            .then((response) => {
                setSubscriptions(response.data);
            })
            .catch((error) =>
                console.log("Error fetching subscriptions:", error)
            );
    }, [token]);

    const handleDisconnect = () => {
        localStorage.clear();
        setToken(null);
        setUser(null);
    };

    const handleSearchbarSelect = (newValue) => {
        navigate("/admin/inventaire/clients/" + newValue.id);
    };

    const popover = (
        <div className="user-options">
            {user.role.modules.some((mod) => mod.value === "users") ? (
                <Link
                    to={`/admin/users/${user.id}`}
                    className="user-options-link"
                    onClick={popoverVisible ? hide : show}
                >
                    <div className="user-options-item users">
                        <User size={16} weight="bold" />
                        <span>Profil</span>
                    </div>
                </Link>
            ) : null}
            {user.role.modules.some((mod) => mod.value === "users") ? (
                <Link
                    to="users"
                    className="user-options-link"
                    onClick={popoverVisible ? hide : show}
                >
                    <div className="user-options-item users">
                        <Users size={16} weight="bold" />
                        <span>Utilisateurs</span>
                    </div>
                </Link>
            ) : null}
            {user.role.modules.some((mod) => mod.value === "roles") ? (
                <Link
                    to="roles"
                    className="user-options-link"
                    onClick={popoverVisible ? hide : show}
                >
                    <div className="user-options-item roles">
                        <UserCircleDashed size={16} weight="bold" />
                        <span>Rôles</span>
                    </div>
                </Link>
            ) : null}
            {user.role.modules.some((mod) => mod.value === "crud") ? (
                <Link
                    to="crud/tasks"
                    className="user-options-link"
                    onClick={popoverVisible ? hide : show}
                >
                    <div className="user-options-item crud">
                        <UserCircleDashed size={16} weight="bold" />
                        <span>CRUD</span>
                    </div>
                </Link>
            ) : null}
            <div className="user-info-hr-horizontal"></div>
            <div
                className="user-options-item logout"
                onClick={handleDisconnect}
            >
                <SignOut size={16} weight="bold" />
                <span>Se déconnecter</span>
            </div>
        </div>
    );

    return user ? (
        <main className="main-app">
            <nav className="main-menu">
                <div className="main-menu-container">
                    <div className="app-logo">
                        <img src={mainLogo} alt="main-logo" width={130} />
                    </div>
                    <div className="menu-container">
                        {user.role.modules.some(
                            (mod) => mod.value === "accounting"
                        ) ? (
                            <div>
                                <div className="menu-item accounting has-sub-items">
                                    <span>Comptabilité</span>
                                </div>
                                {user.role.modules.some(
                                    (mod) =>
                                        mod.value === "accounting" &&
                                        mod.submodules.includes("metas-billing")
                                ) ? (
                                    <Link
                                        to="accounting/metas-billing"
                                        className="menu-link"
                                    >
                                        <div
                                            className={
                                                "menu-sub-item" +
                                                (adminLocation.includes(
                                                    "accounting/metas-billing"
                                                )
                                                    ? " active"
                                                    : "")
                                            }
                                        >
                                            <span>Fact. métas</span>
                                        </div>
                                    </Link>
                                ) : null}
                                {user.role.modules.some(
                                    (mod) =>
                                        mod.value === "accounting" &&
                                        mod.submodules.includes(
                                            "pennylane-subscription"
                                        )
                                ) ? (
                                    <Link
                                        to="accounting/pennylane-subscription"
                                        className="menu-link"
                                    >
                                        <div
                                            className={
                                                "menu-sub-item" +
                                                (adminLocation.includes(
                                                    "accounting/pennylane-subscription"
                                                )
                                                    ? " active"
                                                    : "")
                                            }
                                        >
                                            <span>Abonnements</span>
                                        </div>
                                    </Link>
                                ) : null}
                            </div>
                        ) : null}
                        {user.role.modules.some(
                            (mod) => mod.value === "inventaire"
                        ) ? (
                            <div>
                                <div className="menu-item inventaire has-sub-items">
                                    <span>Inventaire</span>
                                </div>
                                {user.role.modules.some(
                                    (mod) =>
                                        mod.value === "inventaire" &&
                                        mod.submodules.includes("clients")
                                ) ? (
                                    <Link
                                        to="/admin/inventaire/clients"
                                        className="menu-link"
                                    >
                                        <div
                                            className={
                                                "menu-sub-item" +
                                                (adminLocation.includes(
                                                    "inventaire/clients"
                                                )
                                                    ? " active"
                                                    : "")
                                            }
                                        >
                                            <span>Clients</span>
                                        </div>
                                    </Link>
                                ) : null}
                                {user.role.modules.some(
                                    (mod) =>
                                        mod.value === "inventaire" &&
                                        mod.submodules.includes("fournisseurs")
                                ) ? (
                                    <Link
                                        to="/admin/inventaire/fournisseurs"
                                        className="menu-link"
                                    >
                                        <div
                                            className={
                                                "menu-sub-item" +
                                                (adminLocation.includes(
                                                    "inventaire/fournisseurs"
                                                )
                                                    ? " active"
                                                    : "")
                                            }
                                        >
                                            <span>Fournisseurs</span>
                                            <div className="sub-item-info-count">
                                                {countData.countFournisseurs}
                                            </div>
                                        </div>
                                    </Link>
                                ) : null}
                                {user.role.modules.some(
                                    (mod) =>
                                        mod.value === "inventaire" &&
                                        mod.submodules.includes("groupes")
                                ) ? (
                                    <Link
                                        to="/admin/inventaire/groups"
                                        className="menu-link"
                                    >
                                        <div
                                            className={
                                                "menu-sub-item" +
                                                (adminLocation.includes(
                                                    "inventaire/groups"
                                                )
                                                    ? " active"
                                                    : "")
                                            }
                                        >
                                            <span>Groupes</span>
                                            <div className="sub-item-info-count">
                                                {countData.countGroupes}
                                            </div>
                                        </div>
                                    </Link>
                                ) : null}
                                {user.role.modules.some(
                                    (mod) =>
                                        mod.value === "inventaire" &&
                                        mod.submodules.includes("macro")
                                ) ? (
                                    <Link
                                        to="inventaire/macro"
                                        className="menu-link"
                                    >
                                        <div
                                            className={
                                                "menu-sub-item" +
                                                (adminLocation.includes(
                                                    "inventaire/macro"
                                                )
                                                    ? " active"
                                                    : "")
                                            }
                                        >
                                            <span>Macro</span>
                                        </div>
                                    </Link>
                                ) : null}
                                {user.role.modules.some(
                                    (mod) =>
                                        mod.value === "inventaire" &&
                                        mod.submodules.includes("support")
                                ) ? (
                                    <Link
                                        to="inventaire/support"
                                        className="menu-link"
                                    >
                                        <div
                                            className={
                                                "menu-sub-item" +
                                                (adminLocation.includes(
                                                    "inventaire/support"
                                                )
                                                    ? " active"
                                                    : "")
                                            }
                                        >
                                            <span>Support</span>
                                        </div>
                                    </Link>
                                ) : null}
                            </div>
                        ) : null}
                        {user.role.modules.some(
                            (mod) => mod.value === "projects"
                        ) ? (
                            <div>
                                <div className="menu-item projects has-sub-items">
                                    <span>Projets</span>
                                </div>
                                {user.role.modules.some(
                                    (mod) =>
                                        mod.value === "projects" &&
                                        mod.submodules.includes("view")
                                ) ? (
                                    <Link
                                        to="projects-new"
                                        className="menu-link"
                                    >
                                        <div
                                            className={
                                                "menu-sub-item" +
                                                (adminLocation.includes(
                                                    "projects-new"
                                                )
                                                    ? " active"
                                                    : "")
                                            }
                                        >
                                            <span>Projets</span>
                                            <div className="sub-item-info-count">
                                                {countData.countProjects}
                                            </div>
                                        </div>
                                    </Link>
                                ) : null}
                                {user.role.modules.some(
                                    (mod) =>
                                        mod.value === "projects" &&
                                        mod.submodules.includes("internal")
                                ) ? (
                                    <Link to="time-entry" className="menu-link">
                                        <div
                                            className={
                                                "menu-sub-item" +
                                                (adminLocation.includes(
                                                    "projects/internal"
                                                )
                                                    ? " active"
                                                    : "")
                                            }
                                        >
                                            <span>Saisie d'activité</span>
                                        </div>
                                    </Link>
                                ) : null}
                            </div>
                        ) : null}
                        {user.role.modules.some(
                            (mod) => mod.value === "chatbot"
                        ) ? (
                            <div>
                                <div className="menu-item chatbot has-sub-items">
                                    <span>Chatbot</span>
                                </div>
                                {user.role.modules.some(
                                    (mod) =>
                                        mod.value === "chatbot" &&
                                        mod.submodules.includes("facturation")
                                ) ? (
                                    <Link
                                        to="chatbot/facturation"
                                        className="menu-link"
                                    >
                                        <div
                                            className={
                                                "menu-sub-item" +
                                                (adminLocation.includes(
                                                    "chatbot/facturation"
                                                )
                                                    ? " active"
                                                    : "")
                                            }
                                        >
                                            <span>Clients</span>
                                            <div className="sub-item-info-count">
                                                {countData.countLivie}
                                            </div>
                                        </div>
                                    </Link>
                                ) : null}
                                {user.role.modules.some(
                                    (mod) =>
                                        mod.value === "chatbot" &&
                                        mod.submodules.includes("extract")
                                ) ? (
                                    <Link
                                        to="chatbot/extract"
                                        className="menu-link"
                                    >
                                        <div
                                            className={
                                                "menu-sub-item" +
                                                (adminLocation.includes(
                                                    "chatbot/extract"
                                                )
                                                    ? " active"
                                                    : "")
                                            }
                                        >
                                            <span>Extractions</span>
                                        </div>
                                    </Link>
                                ) : null}
                                {user.role.modules.some(
                                    (mod) =>
                                        mod.value === "chatbot" &&
                                        mod.submodules.includes("translate")
                                ) ? (
                                    <Link
                                        to="chatbot/translate"
                                        className="menu-link"
                                    >
                                        <div
                                            className={
                                                "menu-sub-item" +
                                                (adminLocation.includes(
                                                    "chatbot/translate"
                                                )
                                                    ? " active"
                                                    : "")
                                            }
                                        >
                                            <span>Traductions</span>
                                        </div>
                                    </Link>
                                ) : null}
                                {user.role.modules.some(
                                    (mod) =>
                                        mod.value === "chatbot" &&
                                        mod.submodules.includes("souscription")
                                ) ? (
                                    <Link
                                        to="chatbot/souscription"
                                        className="menu-link"
                                    >
                                        <div
                                            className={
                                                "menu-sub-item" +
                                                (adminLocation.includes(
                                                    "chatbot/souscription"
                                                )
                                                    ? " active"
                                                    : "")
                                            }
                                        >
                                            <span>Souscriptions</span>
                                            <div className="sub-item-info-count">
                                                {countData.countSouscription}
                                            </div>
                                        </div>
                                    </Link>
                                ) : null}
                            </div>
                        ) : null}
                        {user.role.modules.some(
                            (mod) => mod.value === "hiring"
                        ) ? (
                            <div>
                                <div className="menu-item tests has-sub-items">
                                    <span>Recrutement</span>
                                </div>
                                {user.role.modules.some(
                                    (mod) =>
                                        mod.value === "hiring" &&
                                        mod.submodules.includes("tests")
                                ) ? (
                                    <Link
                                        to="hiring/tests"
                                        className="menu-link"
                                    >
                                        <div
                                            className={
                                                "menu-sub-item" +
                                                (adminLocation.includes(
                                                    "hiring/tests"
                                                )
                                                    ? " active"
                                                    : "")
                                            }
                                        >
                                            <span>Tests</span>
                                            <div className="sub-item-info-count">
                                                {countData.tests}
                                            </div>
                                        </div>
                                    </Link>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                    <div className="version-container">
                        <span>Version 1.5.2</span>
                    </div>
                </div>
            </nav>
            <div className="main-app-right-part">
                <div className="main-navbar-container">
                    <div className="main-navbar">
                        <button
                            className="main-button return-button"
                            onClick={() => navigate(-1)}
                        >
                            <ArrowLeft size={16} weight="bold" />
                        </button>
                        <div className="searchbar-container">
                            <div className="searchbar-item">
                                <div className="input-container">
                                    <CustomSearchInput
                                        Option={CustomNavbarOption}
                                        placeholder={null}
                                        labelType={"zohoContact"}
                                        entity={searchData}
                                        onChange={handleSearchbarSelect}
                                        value={null}
                                        subscriptions={subscriptions}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="user-info-hr-vertical"></div>
                        <div className="user-info-container">
                            <Tippy
                                content={popover}
                                allowHTML={true}
                                placement={"bottom-end"}
                                offset={[0, 10]}
                                visible={popoverVisible}
                                onClickOutside={hide}
                                interactive={true}
                                appendTo={"parent"}
                            >
                                <div
                                    className="user-item"
                                    onClick={popoverVisible ? hide : show}
                                >
                                    {user && user.backgroundColor && (
                                        <div
                                            className="member-icon"
                                            style={{
                                                backgroundColor:
                                                    user.backgroundColor,
                                            }}
                                        >
                                            {user.firstName.charAt(0)}
                                            {user.lastName.charAt(0)}
                                        </div>
                                    )}
                                    <span>
                                        {user ? (
                                            user.firstName + " " + user.lastName
                                        ) : (
                                            <CircularProgress size={20} />
                                        )}
                                    </span>
                                    <CaretDown size={16} weight="bold" />
                                </div>
                            </Tippy>
                        </div>
                    </div>
                </div>
                <div className="main-router-content">
                    <Routes>
                        <Route path="accounting/*" element={<Accounting />} />
                        <Route path="hiring/*" element={<Hiring />} />
                        <Route path="users/*" element={<UsersInfos />} />
                        <Route path="roles/*" element={<Roles />} />
                        <Route path="projects-new/*" element={<Projects />} />
                        <Route path="inventaire/*" element={<Inventaire />} />
                        <Route path="chatbot/*" element={<Chatbot />} />
                        <Route path="crud/*" element={<Crud />} />
                        <Route path="time-entry/*" element={<TimeEntries />} />
                        <Route
                            path="*"
                            element={
                                <Navigate to={user.role.modules[0].value} />
                            }
                        />
                    </Routes>
                </div>
            </div>
        </main>
    ) : (
        <div className="loader-container">
            <CircularProgress size={30} />
        </div>
    );
};

export default AdminPanel;
