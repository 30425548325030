import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import toastOptions from "../../../assets/constants/toast";
import { useContext } from "react";
import AuthContext from "../../../contexts/AuthContext";

const Settings = ({ user }) => {
    const { token } = useContext(AuthContext);
    const [language, setLanguage] = useState(user.language || "fr");
    const [timezone, setTimezone] = useState(user.timezone || "Europe/Paris");

    const handleSave = () => {
        axios
            .put(
                `${process.env.REACT_APP_UPA_API_HOST}users/${user.id}`,
                {
                    user: {
                        ...user,
                        language,
                        timezone,
                    },
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
            .then(() => {
                toast.success("Paramètres mis à jour", toastOptions);
            })
            .catch((error) => {
                console.log(error);
                toast.error("Erreur lors de la mise à jour", toastOptions);
            });
    };

    return (
        <div className="tab-panel">
            <div className="tab-panel-header">
                <h2>Paramètres</h2>
                <span>Vue générale de votre profil, email, rôle...</span>
            </div>
            <div className="input-row-container">
                <label className="input-label">Langue</label>
                <select className="classic-input" value={language} onChange={(e) => setLanguage(e.target.value)}>
                    <option value="fr">Français</option>
                    <option value="en">Anglais</option>
                </select>
            </div>
            <div className="input-row-container">
                <label className="input-label">Fuseau horaire</label>
                <select className="classic-input" value={timezone} onChange={(e) => setTimezone(e.target.value)}>
                    <option value="Europe/Paris">Europe/Paris</option>
                    <option value="UTC">UTC</option>
                </select>
            </div>
            <button className="main-button" onClick={handleSave}>
                Enregistrer les modifications
            </button>
        </div>
    );
};

export default Settings;
