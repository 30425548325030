import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PermissionsGate from "../PermissionsGate";
import TasksCrud from "./tasks/Task";

const Crud = () => {
    return (
        <Routes>
            <Route
                path="/tasks/*"
                element={
                    <PermissionsGate module="crud" submodule="tasks">
                        <TasksCrud />
                    </PermissionsGate>
                }
            />
            <Route path="*" element={<Navigate to="tasks" />} />
        </Routes>
    );
};

export default Crud;
