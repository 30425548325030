import React, { useContext, useState } from "react";
import axios from "axios";
import AuthContext from "../../../../contexts/AuthContext";
import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import { Bar, BarChart, CartesianGrid, Cell, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { toast } from "react-toastify";
import toastOptions from "../../../../assets/constants/toast";
import CustomTag from "../../../custom/CustomTag";
import { ArrowsClockwise } from "@phosphor-icons/react";

const InventaireSupport = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [refreshData, setResfreshData] = useState(0);
    const [data, setData] = useState();
    const [lastUpdated, setLastUpdated] = useState();
    const { token } = useContext(AuthContext);

    React.useEffect(() => {
        setIsLoaded(false);
        axios
            .get(process.env.REACT_APP_UPA_API_HOST + "desk/get-hours", {
                headers: { Authorization: "Bearer " + token },
            })
            .then((response) => {
                if (response.data) {
                    console.log("Data fetched successfully:", response.data);
                    setData(response.data.hours);
                    setLastUpdated(response.data.lastUpdated);
                    setIsLoaded(true);
                }
            })
            .catch((error) => {
                console.log("Error fetching data:", error);
            });
    }, [refreshData, token]);

    const handleRefresh = () => {
        toast
            .promise(
                axios.get(process.env.REACT_APP_UPA_API_HOST + "desk/import", {
                    headers: { Authorization: "Bearer " + token },
                }),
                {
                    pending: "Mise à jour des temps",
                    success: "Temps mis à jour",
                    error: "Une erreur est survenue",
                },
                toastOptions
            )
            .then(() => {
                console.log("Data refresh completed.");
                setResfreshData(refreshData + 1);
            })
            .catch((error) => {
                console.log("Error refreshing data:", error);
            });
    };

    return isLoaded ? (
        <div className="view-container">
            <div className="view-header">
                <div className="header-title projects">
                    <h1>Support - {dayjs().format("YYYY")}</h1>
                </div>
                <div className="view-buttons">
                    <div className="header-info">
                        <span>Dernière MÀJ : {dayjs(lastUpdated).format("DD/MM à HH:mm")}</span>
                    </div>
                    <button className="main-button" onClick={handleRefresh}>
                        <ArrowsClockwise size={16} weight="bold" />
                        Mettre à jour
                    </button>
                </div>
            </div>
            <div className="support-hours-container">
                <ResponsiveContainer height={data.length * 30}>
                    <BarChart layout="vertical" data={data} margin={{ left: 250 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <YAxis dataKey="data.contact_name" type="category" tickSize={10} tick={{ fontSize: 14, width: 300 }} minTickGap={0} />
                        <XAxis dataKey="supportPercentage" type="number" domain={[0, data[0].totalPercentage]} />
                        <ReferenceLine x={100} strokeWidth={2} stroke="red" strokeDasharray="3 3" />
                        <Tooltip content={<CustomToolTip />} />
                        <Bar label={{ fill: "white", fontSize: 14 }} dataKey="supportPercentage" stackId="a">
                            {data.map((entry, index) => {
                                const color = entry.isPremium ? "#2FB163" : entry.supportHours <= entry.paidSupportHours ? "#2FB163" : "#dc3545";
                                console.log("Rendering bar for:", entry);
                                return <Cell fill={color} key={index} />;
                            })}
                        </Bar>
                        <Bar dataKey="exceedPercentage" stackId="a">
                            {data.map((entry, index) => {
                                return <Cell fill="#dc3545" key={index} />;
                            })}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    ) : (
        <div className="loader-container">
            <CircularProgress size={30} />
        </div>
    );
};

const CustomToolTip = ({ payload, label }) => {
    const contact = payload[0] ? payload[0].payload : null;

    return contact ? (
        <div className="support-tooltip-container">
            <div className="support-tooltip-title">
                <h1 className="margin-right">{label}</h1>
                <CustomTag
                    value={contact.isGroup ? "GROUPE" : contact.isPremium ? "PREMIUM" : "ESSENTIEL"}
                    className={contact.isGroup ? "premium" : contact.isPremium ? "premium" : "debit"}
                />
            </div>
            <div className="support-tooltip-data">
                <span>
                    Heures consommées : <strong>{contact.supportHours}h</strong>
                </span>
                <span>
                    Heures payées : <strong>{contact.paidSupportHours}h</strong>
                </span>
                {contact.exceedHours > 0 ? (
                    <span className="support-exceeded">
                        Excédent : <strong>{contact.exceedHours}h</strong>
                    </span>
                ) : null}
            </div>
            {contact.isGroup ? (
                <div className="support-tooltip-data">
                    <span>Détail des hôtels :</span>
                    {contact.detail.map((hotel) => (
                        <span key={hotel.data.contact_name}>
                            {hotel.data.contact_name} : <strong>{hotel.supportHours}h</strong>
                        </span>
                    ))}
                </div>
            ) : null}
        </div>
    ) : null;
};

export default InventaireSupport;
