import React, { useMemo } from "react";
import { Plus, PencilSimple, Trash } from "@phosphor-icons/react";
import { useNavigate, Link } from "react-router-dom";
import CustomList from "../../../custom/CustomList";
import CustomTag from "../../../custom/CustomTag";
import * as PhosphorIcons from "@phosphor-icons/react";

const TaskList = ({ tasks }) => {
    const navigate = useNavigate();

    const columns = useMemo(
        () => [
            {
                Header: "Nom",
                accessor: "name",
                icon: "pen",
            },
            {
                Header: "Description",
                accessor: "description",
                icon: "document",
                Cell: ({ cell: { value } }) => <CustomTag value={value || "Aucune description"} />,
            },
            {
                Header: "Icône",
                accessor: "iconCode",
                icon: "image",
                Cell: ({ cell: { value } }) => {
                    const IconComponent = PhosphorIcons[value];
                    return IconComponent ? <IconComponent size={16} weight="bold" /> : <span>Icône non trouvée</span>;
                },
            },
            {
                Header: "Couleur",
                accessor: "color",
                icon: "palette",
                Cell: ({ cell: { value } }) => (
                    <div
                        style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: value,
                            borderRadius: "50%",
                        }}
                    />
                ),
            },
            {
                Header: "Actions",
                accessor: "actions",
                icon: "pen",
                Cell: ({ row }) => (
                    <div style={{ display: "flex", gap: "0.5rem" }}>
                        <Link to={`/admin/crud/tasks/update/${row.original.id}`} className="cta-button add-button">
                            <PencilSimple size={16} weight="bold" />
                        </Link>
                        <Link to={`/admin/crud/tasks/delete/${row.original.id}`} className="cta-button delete-button">
                            <Trash size={16} weight="bold" />
                        </Link>
                    </div>
                ),
            },
        ],
        []
    );

    return (
        <div className="view-container">
            <div className="view-header">
                <div className="header-title tasks">
                    <h1>Liste des tâches</h1>
                </div>
                <div className="view-buttons">
                    <button className="main-button blue-button plus" onClick={() => navigate("/admin/crud/tasks/create")}>
                        <Plus size={16} weight="bold" />
                        Créer une tâche
                    </button>
                </div>
            </div>
            <div className="tabs-container">
                <CustomList entity={tasks} columns={columns} isFetchable={false} isClickable={true} />
            </div>
        </div>
    );
};

export default TaskList;
